'use client';

import {
    useWindowSize,
    isMobile,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import Skeleton from '@frontend/jetlend-web-ui/src/ui/Skeleton/Skeleton';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './ClientMainSectionSkeleton.module.scss';
import FormAchievementsSkeleton from '@app/components/FormAchievements/FormAchievementsSkeleton';
import SectionSkeleton from '@app/ui/Section/SectionSkeleton';

export interface IProps {
    hideForm?: boolean;
}

export default function ClientMainSectionSkeleton ({
    hideForm = false,
}: IProps) {
    const { width } = useWindowSize();

    const isMobileSize = isMobile(width);
    const headingClassName = buildClassNames(styles, [
        'form__heading',
        'form__heading--skeleton',
    ]);

    const descriptionClassName = buildClassNames(styles, [
        'form__description',
        'form__description--skeleton',
    ]);

    return (
        <>
            <div className={styles['mobile-view']}>
                <SectionSkeleton hideTitle >
                    <div className={styles['container']}>
                        <div className={styles['mobile-header']}>
                            <Skeleton className={headingClassName} />
                            <Skeleton className={descriptionClassName} />
                            <FormAchievementsSkeleton className={styles['achievements']} />
                        </div>
                        <div className={styles['image-wrapper--mobile']} />
                        {!hideForm && (
                            <div className={styles['mobile-button-wrapper']}>
                                <Skeleton className={styles['button--skeleton']} />
                            </div>
                        )}
                    </div>
                </SectionSkeleton>
            </div>

            {(!isMobileSize) && (
                <div className={styles['desktop-view']}>
                    <SectionSkeleton hideTitle >
                        <div className={styles['container']}>
                            <div className={styles['form']}>
                                <Skeleton className={headingClassName} />
                                <div className={styles['form__subsection']}>
                                    <Skeleton className={descriptionClassName} />
                                    {!hideForm && (
                                        <div className={styles['form__input']}>
                                            <div className={styles['skeleton-container']}>
                                                <Skeleton className={styles['skeleton']} />
                                                <Skeleton className={styles['skeleton']} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <FormAchievementsSkeleton className={styles['achievements--skeleton']} />
                            <div className={styles['image-wrapper']}>
                                <Skeleton />
                            </div>
                        </div>
                    </SectionSkeleton>
                </div>
            )}
        </>
    );
};
