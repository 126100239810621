import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Skeleton from '@frontend/jetlend-web-ui/src/ui/Skeleton/Skeleton';
import styles from './FormAchievements.module.scss';

interface IProps {
    className?: string;
}

export default function FormAchievementsSkeleton({ className }: IProps) {

    const achievementClassName = buildClassNames(styles, [
        'achievement',
        'achievement--skeleton',
    ]);

    return (
        <div className={mergeClassNames([styles['achievements--skeleton'], className])}>
            <Skeleton className={achievementClassName} />
            <Skeleton className={achievementClassName} />
            <Skeleton className={achievementClassName} />
            <Skeleton className={achievementClassName} />
        </div>
    );
}
